import { FileInput } from "@mantine/core";

// only use in dev
const InvisibleFileInput = ({ onChange, reference }: { onChange: (pictures: string) => Promise<void>, reference: React.RefObject<HTMLButtonElement> }) => {

    const toBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const onInputChange = async (payload: File[]) => {
        const pictures: { filename: string; base64: string }[] = []
        for (let i = 0; i < payload.length; i++) {
            const filename = payload[i].name
            const base64 = await toBase64(payload[i])
            pictures.push({ filename, base64: base64 as string })
        }
        onChange(JSON.stringify(pictures))
    }

    return (
        <div className="tw-invisible tw-h-0" > <FileInput ref={reference} onChange={onInputChange} accept="image/png,image/jpeg" multiple /></div>
    )
}

export default InvisibleFileInput