import { useQuery, useQueryClient } from "@tanstack/react-query";
import http from '@plugin/http'
import { useSearchParams } from "react-router-dom";
import ICar, { IPhoto, IProvider } from "types/Car";
import useProviders from "hooks/useProviders";
import { ActionIcon, Button, Checkbox, CopyButton, Group, Image, Input, LoadingOverlay, Modal, Select, TextInput, Textarea, Tooltip, rem } from "@mantine/core";
import { useCallback, useMemo, useRef, useState } from "react";
// import DropZone from "@components/DropZone";
import useEventReactNative from "hooks/useEventReactNative";
import useIsEnv from "hooks/useIsEnv";
import usePhoto from "hooks/usePhoto";
import useS3 from "hooks/useS3";
import InvisibleFileInput from "@components/InvisibleFileInput";
import { IconCheck, IconCopy, IconSend } from "@tabler/icons-react";
import JSZip from "jszip"
import { useForm } from "@mantine/form";

type Photo = IPhoto & { checked: boolean }

const DetailCar = () => {
    const [searchParams] = useSearchParams();

    const carProviders = useProviders()
    const [selectedCode, setSelectedCode] = useState<string>('none');
    const [filteredPrimaryPhotos, setFilteredPrimaryPhotos] = useState<Photo[]>([])
    const [filteredSecondaryPhotos, setFilteredSecondaryPhotos] = useState<Photo[]>([])
    const [openModal, setOpenModal] = useState(false)

    const [shortLink, setShortLink] = useState<string | undefined>()

    const form = useForm({
        initialValues: {
            body: '',
            destinataire: '',
        },
        validate: {
            body: (value) => (value.length <= 0 ? 'Veuillez renseigner un corps de mail' : null),
            destinataire: (value) => (/^\S+@\S+$/.test(value) ? null : 'Veuillez renseigner un destinaire'),
        }
    });

    const { data: originalCar, isLoading, isFetched, } = useQuery<ICar>(['car_by_id', searchParams], async () => {
        const data = (await http.get('/car_by_id', { params: { 'carId': searchParams.get('id') } })).data as ICar
        filterPrimaryPhoto(selectedCode, data?.primary_photos)
        filterSecondaryPhoto(selectedCode, data?.secondary_photos)
        return data
    }, {
        retry: false,
        refetchOnWindowFocus: false,
    })
    const { isProd } = useIsEnv()

    const { addPrimaryPhoto, addSecondaryPhoto } = usePhoto()
    const { uploadToS3 } = useS3()
    const primaryRef = useRef<HTMLButtonElement>(null);
    const secondaryRef = useRef<HTMLButtonElement>(null);
    // const [, setSelectedPictures] = useState<{ name: string, base64: string }[]>([])
    const { sendMessage } = useEventReactNative()

    const queryClient = useQueryClient()

    const isCodeSelected = selectedCode && selectedCode !== 'none'

    const anyCheckedPhoto = filteredPrimaryPhotos.filter(photo => photo.checked).length > 0 || filteredSecondaryPhotos.filter(photo => photo.checked).length > 0
    const filteredCheckedPhoto = [...filteredPrimaryPhotos.filter(photo => photo.checked), ...filteredSecondaryPhotos.filter(photo => photo.checked)]

    const filterPrimaryPhoto = useCallback((selectedCode?: string, primary_photos?: ICar['primary_photos']) => {
        let tmp = primary_photos?.map(photo => ({ ...photo, checked: false })) ?? []
        if (selectedCode && selectedCode !== 'none')
            tmp = tmp.filter((photo) => photo.code === selectedCode)
        setFilteredPrimaryPhotos(tmp)
    }, [])

    const filterSecondaryPhoto = useCallback((selectedCode?: string, secondary_photos?: ICar['secondary_photos']) => {
        let tmp = secondary_photos?.map(photo => ({ ...photo, checked: false })) ?? []
        if (selectedCode && selectedCode !== 'none')
            tmp = tmp.filter((photo) => photo.code === selectedCode)
        setFilteredSecondaryPhotos(tmp)
    }, [])

    const providersCodes = useMemo(() => {
        return carProviders?.reduce((accumulator: { value: string, label: string }[], currentValue) => {
            if (currentValue._id === (originalCar?.provider as IProvider)?._id) {
                return accumulator.concat(currentValue.codes.map((code: string) => ({ value: code, label: code })))
            }
            return accumulator
        }, [{ label: "aucun code", value: "none" }]) ?? [{ label: "aucun code", value: "none" }]
    }, [carProviders, originalCar])

    const onCodeChange = (val: string) => {
        setSelectedCode(val)
        filterPrimaryPhoto(val, originalCar?.primary_photos)
        filterSecondaryPhoto(val, originalCar?.secondary_photos)
    }


    const onToggleAlbum = () => {
        sendMessage({ type: "toggleAlbum" })
    }

    // const onPictures = async (pictures: string) => {
    //     console.log(pictures)
    //     const selectedPictures = JSON.parse(pictures).map((el: any) => ({ name: el.filename, base64: el.base64 }))
    //     setSelectedPictures(selectedPictures)
    //     const { key } = await uploadToS3(selectedPictures[0].base64)
    //     await http.post('/secondary_photos', {
    //         carId: searchParams.get('id'),
    //         photos: [{ name: selectedPictures[0].name, code: selectedCode || providersCodes[0].value, path: key }]
    //     })

    //     invalidCar()
    // }

    const invalidCar = () => {
        queryClient.invalidateQueries(['car_by_id', searchParams])
    }

    // TODO - in react native should emit / receive event
    // TODO -> fix add on react native ( add primary / secondary )
    const onAddPrimary = async () => {
        if (isProd)
            onToggleAlbum()
        else
            primaryRef.current?.click()
    }

    const addPrimary = async (pictures: string) => {
        console.log(pictures)
        if (isCodeSelected) {
            const json_pictures = JSON.parse(pictures).map((el: any) => ({ name: el.filename, base64: el.base64 }))

            console.log(pictures, json_pictures)
            const { key } = await uploadToS3(json_pictures[0].base64)
            await addPrimaryPhoto(searchParams.get('id') as string, [{ name: json_pictures[0].name, code: selectedCode, path: key }])
            invalidCar()
        }
    }

    // TODO -> fix add on react native ( add primary / secondary )
    const onAddSecondary = () => {
        if (isProd)
            onToggleAlbum()
        else
            secondaryRef.current?.click()
    }

    const addSecondary = async (pictures: string) => {
        console.log(pictures)
        if (isCodeSelected) {
            const json_pictures = JSON.parse(pictures).map((el: any) => ({ name: el.filename, base64: el.base64 }))

            const { key } = await uploadToS3(json_pictures[0].base64)
            await addSecondaryPhoto(searchParams.get('id') as string, [{ name: json_pictures[0].name, code: selectedCode, path: key }])
            invalidCar()
        }
    }

    const onSendEmail = async () => {
        // await fetch(filteredCheckedPhoto[0].path)
        let promises = await Promise.allSettled(filteredCheckedPhoto.map(photo => {
            return new Promise(resolve => {
                http.get(photo.path, {
                    responseType: 'blob',
                    withCredentials: false
                }).then(response => resolve({
                    name: photo.name,
                    code: photo.code,
                    blob: response.data
                })).catch(err => console.log(err))
            })
        }))

        console.log(promises)
        promises = promises.filter(p => p.status === 'fulfilled')
        const zip = new JSZip();
        // @ts-expect-error
        promises.forEach(photo => { zip.file(photo.value.name, photo.value.blob) })

        const blobArchive = await zip.generateAsync({ type: "blob" })
        const { key } = await uploadToS3(blobArchive)
        // @ts-expect-error
        const newShortLink = (await http.post('/attachment', { s3_path: key, carId: originalCar?._id, photos: promises.map(p => p.value.name) })).data
        setShortLink(newShortLink)
        form.values.body = `Madame, Monsieur
        
        Veuillez trouver le lien de télécharger pour la demande d'information du véhicule ${originalCar?.marque || ''} ${originalCar?.famille || ''} ${originalCar?.version || ''} 

        ${isProd ? 'isProd/' : 'http://localhost:8080/link/'}${newShortLink}

        Cordialement
        `
        setOpenModal(true)
    }

    const sendEmail = async ({ body, destinataire }: { body: string, destinataire: string }) => {
        await http.post('/send_email', { to: destinataire, body, short_link: shortLink })
        setOpenModal(false)
    }

    return (
        <>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <InvisibleFileInput onChange={addPrimary} reference={primaryRef} />
            <InvisibleFileInput onChange={addSecondary} reference={secondaryRef} />
            <div className="tw-mt-2 tw-flex tw-flex-nowrap tw-space-x-4">
                <div className="tw-flex-1">
                    <Input
                        placeholder={typeof originalCar?.provider !== 'string' ? originalCar?.provider.user : ''}
                        id="providerCodeSelector"
                        disabled
                    />
                </div>
                <div className="tw-flex-1">
                    <Select
                        defaultValue="none"
                        placeholder="Code"
                        data={providersCodes}
                        id="providerCodeSelector"
                        onChange={onCodeChange}
                        disabled={!isFetched || !originalCar}
                    />
                </div>
            </div>
            <div className="tw-m-2 tw-flex tw-flex-nowrap tw-space-x-4">
                <h3>{typeof originalCar?.provider !== 'string' && originalCar?.provider.user}</h3>
            </div>

            <div className="tw-flex tw-sticky tw-top-0">
                <Button leftIcon={<IconSend />} disabled={!anyCheckedPhoto} onClick={onSendEmail} fullWidth>
                    Envoyer un email
                </Button>
            </div >

            {/* <DropZone onClick={onToggleAlbum} usingEvent={isProd} onChange={onPictures} /> */}

            <div className="tw-mt-2">
                <h1 className="tw-text-3xl tw-font-bold">
                    Informations
                </h1>
                <div>{originalCar?.immatriculation}</div>
                <div>-------</div>
                <div>-------</div>
                <div>-------</div>
            </div>
            <div className="tw-mt-2">
                <h1 className="tw-text-3xl tw-font-bold">
                    Photos
                </h1>
                <h1 className="tw-text-lg tw-font-bold">
                    photo véhicule
                    {isCodeSelected &&
                        <Button onClick={onAddPrimary} radius="xl" size="xs" className="tw-ml-2" compact uppercase>
                            Ajouter
                        </Button>}
                </h1>
                <div className="tw-flex tw-flex-wrap">
                    <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-8">
                        {filteredPrimaryPhotos?.map((photo, index) =>
                            <div className="tw-w-2/5 tw-relative" key={photo.path + '1'}>
                                <div className="tw-absolute tw-z-10 tw-right-0">
                                    <Checkbox
                                        onChange={() => {
                                            let arr = [...filteredPrimaryPhotos]
                                            arr.splice(index, 1, { ...filteredPrimaryPhotos[index], checked: !filteredPrimaryPhotos[index].checked })
                                            setFilteredPrimaryPhotos(arr)
                                        }}
                                        checked={photo.checked}
                                    />
                                </div>
                                <Image
                                    fit="contain"
                                    radius="md"
                                    src={photo.path}
                                    alt="Random unsplash image"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <h1 className="tw-text-lg tw-font-bold">
                    photo privés
                    {isCodeSelected && <Button onClick={onAddSecondary} radius="xl" size="xs" className="tw-ml-2" compact uppercase>
                        Ajouter
                    </Button>}
                </h1>
                <div className="tw-flex tw-flex-wrap">
                    <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-8">
                        {filteredSecondaryPhotos?.map((photo, index) =>
                            <div className="tw-w-2/5 tw-relative" key={`${photo.path}_${index}`}>
                                <div className="tw-absolute tw-z-10 tw-right-0">
                                    <Checkbox
                                        onChange={() => {
                                            let arr = [...filteredSecondaryPhotos]
                                            arr.splice(index, 1, { ...filteredSecondaryPhotos[index], checked: !filteredSecondaryPhotos[index].checked })
                                            setFilteredSecondaryPhotos(arr)
                                        }}
                                    />
                                </div>
                                <Image
                                    fit="contain"
                                    radius="md"
                                    src={photo.path}
                                    alt="Random unsplash image"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <Modal
                opened={openModal}
                onClose={() => { setOpenModal(false) }}
                title="Formulaire d'email client"
                fullScreen
                transitionProps={{ transition: 'fade', duration: 200 }}
            >
                <form onSubmit={form.onSubmit(sendEmail)}>
                    <TextInput
                        id="body"
                        label="Destinataire"
                        placeholder="email@exemple.fr"
                        {...form.getInputProps('destinataire')}
                    />
                    <Textarea
                        id="body"
                        radius="md"
                        label="Contenu"
                        placeholder="Madame, Monsieur ..."
                        withAsterisk
                        autosize
                        {...form.getInputProps('body')}
                    />
                    <CopyButton value={`${isProd ? 'isProd/' : 'http://localhost:8080/link/'}${shortLink}`} timeout={2000}>
                        {({ copied, copy }) => (
                            <Tooltip label={copied ? 'Copier' : 'Copier le lien'} withArrow position="right">
                                <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
                                    {copied ? (
                                        <IconCheck style={{ width: rem(16) }} />
                                    ) : (
                                        <IconCopy style={{ width: rem(16) }} />
                                    )}
                                </ActionIcon>
                            </Tooltip>
                        )}
                    </CopyButton>
                    <Group position="right" mt="md">
                        <Button type="submit" fullWidth disabled={!form.isValid()}>Envoyer</Button>
                    </Group>
                </form>
            </Modal >
        </>
    )
}

export default DetailCar