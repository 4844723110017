import React from 'react';
import { Routes, Route } from "react-router-dom";
import Layout from "@layout/layout"
import Home from '@pages/Home'
import Login from '@pages/Login'
import Logout from '@pages/Logout'
import Search from '@pages/Search'
import NoMatch from '@pages/NoMatch'
import AddCar from '@pages/AddCar'
import DetailCar from '@pages/DetailCar';
import DownloadAttachment from '@pages/DownloadAttachment'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="search" element={<Search />} >
          <Route path=":query" element={<Search />} />
        </Route>
        <Route path="car" element={<AddCar />} >
          <Route path="add" index element={<AddCar />} />
        </Route>
        <Route path="detail_car" element={<DetailCar />} >
          <Route path=":id" element={<DetailCar />} />
        </Route>
        <Route path="link/:link?" element={<DownloadAttachment />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  )
}

export default App;
