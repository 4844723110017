import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import http from '@plugin/http'
import IUser from "types/User"
import axios from "axios"


function isEmpty(obj: any) {
    return !obj || Object.keys(obj).length === 0;
}

export const useGetUserAccount = ({ enable = true }) => useQuery(['user'], async () => {
    try {
        const response = await http.get<IUser>('/whoami')
        return response.data

    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status && error.response.status !== 401)
            throw error
        return {}
    }
}, {
    enabled: enable && document.cookie?.length > 0,
    onError(_error) {},
    placeholderData: {},
    retry: false,
    refetchOnWindowFocus: false,
})

export const useIsLoggin = () => {
    const { data } = useGetUserAccount({})
    console.log('useIsLoggin', data)
    return !isEmpty(data)
}

export const useLogin = () => {
    const queryClient = useQueryClient();

    return useMutation(async (form: { username: string, password: string }) => {
        return await http.post('/login', form)
    }, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(["user"])
        },
        onError: error => {
            console.log(error);
        }
    })
}

export const useLogout = () => {
    const queryClient = useQueryClient();

    return useMutation(async () => {
        return await http.post('/logout')
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user'] })
        },
        onError: error => {
            console.log(error);
        }
    })
}