import { useEffect, useMemo, useState } from 'react';
import AdvancedEdition from '@components/AdvancedEdition';
import { Box, Button, Group, Image, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMutation } from '@tanstack/react-query'
import http from '@plugin/http'
import DropZone from '@components/DropZone';
import { useGetUserAccount } from 'hooks/useUserAccount';
import useEventReactNative from 'hooks/useEventReactNative';
import useIsEnv from 'hooks/useIsEnv';
import useS3 from 'hooks/useS3';
import usePhoto from 'hooks/usePhoto';
import { useNavigate } from 'react-router-dom';
declare global {
  interface Window {
    ReactNativeWebView: any;
    new_pictures: any
  }
}



const AddCar = () => {
  const { data: user } = useGetUserAccount({})
  const [selected_pictures, setSelectedPictures] = useState<{ name: string, base64: string }[]>([])
  const { addListener, sendMessage } = useEventReactNative()
  const { isProd } = useIsEnv()
  const { addPrimaryPhoto } = usePhoto()
  const { uploadToS3 } = useS3()
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      immatriculation: '',
      marque: '',
      modele: '',
      annee: '',
      kms: '',
      type: '',
      pays: '',
      version: '',
      couleur: '',
      providerId: '',
      providerCode: ''
    },
    validate: {
      immatriculation: (value) => (value.length < 2 ? 'Immatriculation incorrecte' : null),
      providerId: (value) => (!value ? 'Fichier de données incorrect' : null),
      providerCode: (value) => (selected_pictures.length > 0 && !value ? 'Code du fichier de données incorrect' : null)
    }
  });

  const providerIds = useMemo(() =>
    user?.provider?.map((el: any) => ({ value: el._id, label: `${el.user} - (${el.codes.join(',')})` })) ?? []
    , [user])

  const providersCodes = useMemo(() => {
    return user?.provider?.reduce((accumulator: { value: string, label: string }[], currentValue) => {
      if (currentValue._id === form?.values?.providerId)
        return accumulator.concat(currentValue.codes.map((code: string) => ({ value: code, label: code })))
      return accumulator
    }, []) ?? []
  }, [user, form?.values?.providerId])

  const onPictures = (pictures: string) => {
    setSelectedPictures(JSON.parse(pictures).map((el: any) => ({ name: el.filename, base64: el.base64 })))
  }

  useEffect(() => {
    if (isProd)
      addListener((payload) => {
        onPictures(payload)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const { mutate } = useMutation((form) => {
    return http.post('/car', form)
  }, {
    onSuccess: async data => {
      if (selected_pictures.length > 0) {
        const { key } = await uploadToS3(selected_pictures[0].base64)
        await addPrimaryPhoto(data.data, [{ name: JSON.parse(selected_pictures[0].name), code: form.values.providerCode, path: key }])
        navigate({ pathname: '/detail_car', search: `id=${data.data}` })
        return data
      }
      return ""
    },
    onError: ((err) => {
      console.log(err);
    })
  })

  const onSubmit = (values_form: any) => {
    if (!form.isValid())
      return
    mutate(values_form);
  }

  const onToggleAlbum = () => {
    sendMessage({ type: "toggleAlbum" })
  }

  return (
    <>
      <Box>
        <form onSubmit={form.onSubmit(onSubmit)}>
          <TextInput
            label="Immatriculation"
            placeholder="AB-768-KD"
            id="immatriculation"
            {...form.getInputProps('immatriculation')}
            required
          />
          <Select
            label="Fichier de données"
            placeholder="Fichier de données"
            data={providerIds}
            id="providerIds"
            {...form.getInputProps('providerId')}
            required
          />

          {selected_pictures.length > 0 && form.values.providerId &&
            <Select
              label="Code"
              placeholder="Code"
              data={providersCodes}
              id="providerCodeSelector"
              {...form.getInputProps('providerCode')}
              required
            />}
          <Button onClick={onToggleAlbum} >toto</Button>
          <DropZone onClick={onToggleAlbum} usingEvent={isProd} onChange={onPictures} />
          {
            selected_pictures?.map((picture: any, index: number) =>
              <Image
                key={index}
                radius="md"
                withPlaceholder
                fit="contain"
                height={80}
                src={picture.base64}
                alt="Random unsplash image"
              />
            )
          }
          <AdvancedEdition text="Informations supplémentaires">
            <TextInput
              label="Marque"
              placeholder="Mercedes"
              {...form.getInputProps('marque')}
            />
            <TextInput
              label="modele"
              placeholder="modele"
              {...form.getInputProps('modele')}
            />
            <TextInput
              label="Année"
              placeholder="Année"
              {...form.getInputProps('annee')}
            />
            <TextInput
              label="Kilomètres"
              placeholder="Kilomètres"
              {...form.getInputProps('kms')}
            />
            <TextInput
              label="type"
              placeholder="VO/VD/VN"
              {...form.getInputProps('type')}
            />
            <TextInput
              label="Pays"
              placeholder="France"
              {...form.getInputProps('pays')}
            />
            <TextInput
              label="Version"
              placeholder="Version 350 AVANTGARDE 7GTRO"
              {...form.getInputProps('version')}
            />
            <TextInput
              label="couleur"
              placeholder="Noir"
              {...form.getInputProps('couleur')}
            />
          </AdvancedEdition>
          <Group position="right" mt="md">
            <Button type="submit" fullWidth disabled={!form.isValid()}>Ajouter</Button>
          </Group>
        </form>
      </Box>
    </>
  )
}

export default AddCar