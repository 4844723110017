import http from '@plugin/http'
import b64toBlob from 'b64-to-blob';

const useS3 = () => {
    const uploadToS3 = async (image: string | Blob) => {
        const awsPreSignedPost = (await http.get('/presign_url')).data;
        const formData = new FormData();
        for (const key in awsPreSignedPost.formData) {
            formData.append(key, awsPreSignedPost.formData[key])
        }

        // remove 'data:image/jpeg;base64,' from the base64 to blobing itt???
        // might remove this string from the app because it is not 'proper' base64 
        formData.append('file', typeof image === 'string' ? b64toBlob(image.slice(image.indexOf(',') + 1), 'image/png') : image)

        const res = await (http.post(awsPreSignedPost.postURL, formData, { withCredentials: false }))
        return { data: res.data, key: awsPreSignedPost.formData['key'] }
    }

    return {
        uploadToS3
    }
}

export default useS3