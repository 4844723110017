import { useEffect } from "react"
import useIsEnv from "./useIsEnv";

interface EventMessage {
    data: string
    type: string
}

type EventFunctionCallBack = { (event: MessageEvent<EventMessage>): void; }

type ListenerFunction = (data: string) => void

const useEventReactNative = (callbacks?: ListenerFunction[]) => {
    const { isProd } = useIsEnv()

    const functionToClean: EventFunctionCallBack[] = []

    const addListener = (callback: ListenerFunction) => {
        const wrapper = (payload: MessageEvent<EventMessage>) => {
            if (payload.type === 'detailcustomerRN')
                callback(payload.data as unknown as string)
        }

        functionToClean.push(wrapper)
        window.addEventListener("message", wrapper);
    }

    const addListeners = (callbacks: ListenerFunction[]) => {
        callbacks.forEach(callback => addListener(callback))
    }

    const sendMessage = (msg: any) => {
        window.ReactNativeWebView.postMessage(JSON.stringify(msg))
    }

    const cleanListeners = () => {
        functionToClean?.forEach((fn) => {
            window.removeEventListener('message', fn)
        });
    }

    useEffect(() => {
        if (isProd && callbacks) {
            addListeners(callbacks)
            return cleanListeners()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callbacks])

    return {
        addListener,
        sendMessage
    }
}

export default useEventReactNative