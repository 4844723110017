import { FileInput } from "@mantine/core";
import { useRef } from "react";

const DropZone = ({ onClick, onChange, usingEvent = false }: { onClick?: () => void, onChange: (pictures: string) => void, usingEvent?: boolean }) => {
    const ref = useRef<HTMLButtonElement>(null);

    const toBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    // only use in dev
    const onInputChange = async (payload: File[]) => {
        const pictures: { filename: string; base64: string }[] = []
        for (let i = 0; i < payload.length; i++) {
            const filename = JSON.stringify(payload[i].name)
            const base64 = await toBase64(payload[i])
            pictures.push({ filename, base64: base64 as string })
        }
        onChange(JSON.stringify(pictures))
    }

    return (
        <div className="tw-max-w-xl">
            {!usingEvent &&
                <div className="tw-invisible tw-h-0">
                    <FileInput ref={ref} onChange={onInputChange} accept="image/png,image/jpeg" multiple />
                </div>}
            <label
                className="tw-flex tw-justify-center tw-w-full tw-h-32 tw-px-4 tw-transition tw-bg-white tw-border-2 tw-border-gray-300 tw-border-dashed tw-rounded-md tw-appearance-none tw-cursor-pointer tw-hover:border-gray-400 tw-focus:outline-none"
                onClick={() => { usingEvent ? onClick?.() : ref.current?.click() }}>
                <span className="tw-flex tw-items-center tw-space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="tw-w-6 tw-h-6 tw-text-gray-600" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                    </svg>
                    <span className="tw-font-medium tw-text-gray-600">
                        Selectionner dans votre
                        <span className="tw-text-blue-600 tw-underline tw-ml-1">album</span>
                    </span>
                </span>
                <input type="file" name="file_upload" disabled className="tw-hidden" />
            </label>
        </div>
    )
}

export default DropZone