import http from '@plugin/http'
import ICar from 'types/Car'

const usePhoto = () => {
    const addPrimaryPhoto = (carId: string, photos: ICar['primary_photos']) =>
        http.post('/primary_photos', { carId, photos })

    const addSecondaryPhoto = (carId: string, photos: ICar['primary_photos']) =>
        http.post('/secondary_photos', { carId, photos })

    return { addPrimaryPhoto, addSecondaryPhoto }
}

export default usePhoto