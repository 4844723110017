import { useMemo } from "react"
import { useGetUserAccount } from "./useUserAccount"

const useProviders = () => {
    const { data: user } = useGetUserAccount({})

    const carProviders = useMemo(() => user?.provider, [user])

    return carProviders
}

export default useProviders