import { UnstyledButton, Text, Group } from "@mantine/core"
import { useToggle } from "@mantine/hooks"
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react"

const AdvancedEdition = ({ children, text = 'Recherche avancée' }: { children?: React.ReactNode, text?: string }) => {
    const [advancedResearch, setAdvancedResearch] = useToggle()
    return (
        <div className="tw-flex tw-flex-col tw-my-3">
            <UnstyledButton className="tw-text-blue-500 hover:tw-text-blue-700 hover:tw-underline tw-flex tw-place-content-center">
                <Group className="tw-gap-x-1" onClick={() => setAdvancedResearch()}>
                    {
                        advancedResearch ?
                            <IconChevronDown /> :
                            <IconChevronUp />
                    }
                    <Text size="sm" className="tw-font-semibold" >{text}</Text>

                </Group>
            </UnstyledButton>
            <div>
                {advancedResearch && children}
            </div>
        </div >
    )
}

export default AdvancedEdition