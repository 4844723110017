import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsLoggin, useLogout } from 'hooks/useUserAccount';

function Logout() {
  const isLoggin = useIsLoggin()
  const navigate = useNavigate();
  const { mutate: mutateLogout } = useLogout()

  useEffect(() => {
    if (isLoggin) {
      mutateLogout()
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <></>
  )
}

export default Logout