import {
  createStyles,
  Header,
  Group,
  Button,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
} from '@mantine/core';
import { MantineLogo } from '@mantine/ds';
import { useDisclosure } from '@mantine/hooks';
import {
  IconPlus,
  IconSearch,
} from "@tabler/icons-react"
import { Link, Outlet } from 'react-router-dom';
import { useIsLoggin } from 'hooks/useUserAccount';

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan('sm')]: {
      height: 42,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md}px`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

export function HeaderMegaMenu() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const { classes, theme } = useStyles();

  const isLoggin = useIsLoggin()

  return (
    <div className="tw-mb-12 tw-relative">
      <Box>
        <Header height={60} px="md">
          <Group position="apart" sx={{ height: '100%' }}>
            <MantineLogo size={30} />
            <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          </Group>
        </Header>

        <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <ScrollArea sx={{ height: 'calc(100vh - 60px)' }} mx="-md">
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />
            <Link className={classes.link} onClick={toggleDrawer} to="/">
              Home
            </Link>
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

            <Group position="center" grow pb="xl" px="md">
              {
                !isLoggin ?
                  <>
                    <Button variant="default" component={Link} to="/login" onClick={toggleDrawer}>Login</Button>
                    <Button>Sign up</Button>
                  </>
                  :
                  <Button variant="default" component={Link} to="/logout" onClick={toggleDrawer}>Logout</Button>

              }

            </Group>
          </ScrollArea>
        </Drawer>
      </Box>
      <div className="tw-mx-4">
        <Outlet />
      </div>
      {
        !drawerOpened && isLoggin &&
        <Box className="tw-fixed tw-inset-x-0 tw-bottom-0 tw-bg-slate-400 tw-drop-shadow-md">
          <div>
            <div className="">
              <Button.Group>
                <Button fullWidth variant="default" size="md" className="tw-border-r-0" component={Link} to="/search" style={{ "borderRightWidth": "0px" }}>
                  <div className="tw-flex tw-flex-col tw-content-center tw-place-content-center">
                    <div className="tw-content-center">
                      <IconSearch className="tw-w-full" size={24} color="#4d5053" />
                    </div>
                  </div>
                </Button>
                <Button fullWidth variant="default" size="md" component={Link} to="/car" style={{ "borderLeftWidth": "0px" }}>
                  <div className="tw-flex tw-flex-col tw-content-center tw-place-content-center">
                    <div className="tw-content-center">
                      <IconPlus className="tw-w-full" size={24} color="#4d5053" />
                    </div>
                  </div>
                </Button>
              </Button.Group>
            </div>
          </div>
        </Box>
      }
    </div>
  );
}

export default HeaderMegaMenu