import React from 'react';
import SearchFilters from "@components/SearchFilters"
import ListResult from "@components/ListResult"
import LastResearchResult from "@components/LastResearchResult"
import { Text } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';

function Search() {
  const [searchParams] = useSearchParams();

  return (
    <>
      {!searchParams.toString() ?
        <>
          <Text size="lg" className="tw-font-bold tw-my-6">Rechercher un véhicule</Text>
          <SearchFilters />
          <LastResearchResult />
        </>
        :
        <>
          <ListResult />
        </>
      }

    </>
  );
}

export default Search