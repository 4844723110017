import { Image } from "@mantine/core"
import { useNavigate } from "react-router-dom";

import "./ItemResult.css"

const ItemResult = ({ title, immatriculation, createdBy, km, picture, carId }: { title?: string, immatriculation?: string, createdBy?: string, km?: string, picture?: string; carId: string }) => {
  const navigate = useNavigate();

  return (
    <div className="tw-flex tw-flex-row hover:tw-cursor-pointer hover:tw-bg-slate-300 tw-p-2" onClick={() => { navigate({ pathname: '/detail_car', search: `id=${carId}` }) }}>
      <div className="tw-basis-2/5">
        <div >
          <Image
            fit="contain"
            radius="md"
            src={picture ?? "https://images.unsplash.com/photo-1511216335778-7cb8f49fa7a3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"}
            alt="Random unsplash image"
          />
        </div>
      </div>
      <div className="tw-flex tw-basis-3/5 tw-flex-col tw-ml-4 tw-text-sm tw-space-y-1 tw-justify-center">
        <span className="tw-text-ellipsis tw-overflow-hidden marque-modele-version-ellipsed tw-font-semibold">{title}</span>
        <div>{immatriculation}</div>
        <div className="tw-text-xs"></div>
        {
          createdBy &&
          <div className="tw-text-xs">Ajouter par {createdBy} </div>
        }

        {/* / Modifier par Cyril le xx/xx/xx */}
        {km && <div className="tw-text-xs">{km ?? 0} km</div>}


      </div>
    </div>
  )
}

export default ItemResult