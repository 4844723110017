import axios from "axios";

// const UNAUTHORIZED = 401;

const env = {
    test: {
        axios: { baseURL: "toto" }
    },
    development: {
        axios: {
            baseURL: "http://localhost:3000/api"
        }
    }, production: {
        axios: {
            baseURL: "https://www.nextfleet.fr/api"
        }
    }, staging: {
        axios: {
            baseURL: "https://www.staging.nextfleet.fr/api"
        }
    }
}

let config = {
    baseURL: env[process.env.NODE_ENV || "development"]?.axios?.baseURL,
    // timeout: 60 * 1000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
};

const instance = axios.create(config);

export default instance