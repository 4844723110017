import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from '@plugin/http'
import { Center, RingProgress, Text, ThemeIcon } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

const DownloadAttachment = () => {
    const { link } = useParams()
    const navigate = useNavigate()
    const [progress, setProgress] = useState<number | undefined>(0)

    const isDownloadFinished = !!progress && progress >= 100

    console.log(isDownloadFinished)
    useEffect(() => {
        if (!link)
            navigate('/')

        const fn = async () => {
            if (link) {
                const s3Link = (await http.get(`/a/${link}`)).data

                const data = (await http.get(s3Link, {
                    responseType: 'blob',
                    onDownloadProgress(progressEvent) {
                        console.log(progressEvent)
                        if (progressEvent.progress)
                            setProgress(Math.trunc(progressEvent.progress * 100))
                    },
                })).data

                const fileName = 'archive.zip';
                const tempEl = document.createElement("a");
                document.body.appendChild(tempEl);
                // @ts-expect-error
                tempEl.style = "display: none";
                const url = window.URL.createObjectURL(data);
                tempEl.href = url;
                tempEl.download = fileName;
                tempEl.click();
                window.URL.revokeObjectURL(url);
            }
        }

        fn()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ringLabel = isDownloadFinished ?
        (<Center>
            <ThemeIcon color="teal" variant="light" radius="xl" size="xl">
                <IconCheck size={22} />
            </ThemeIcon>
        </Center>) :
        (<Text color="blue" weight={700} align="center" size="xl">
            {progress}
        </Text>)

    return (
        <div className="tw-justify-center tw-flex">
            <RingProgress
                sections={[{ value: progress || 0, color: isDownloadFinished ? 'teal' : 'blue' }]}
                label={ringLabel}
            />
        </div>)
}

export default DownloadAttachment